@import "./icons.scss";

.head {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 23px;
	z-index: 100;

	.logo {
		position: relative;
		width: 123px;
		height: 26px;
		background-image: $logo;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;

		&:before {
			content: "";
			position: absolute;
			top: -80px;
			transform: translateX(30px);
			display: inline-block;
			width: 72px;
			height: 72px;
			background-image: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
			border-radius: 50%;
			filter: blur(30px);
		}
	}

	.languages {
		width: 56px;
		height: 26px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 3px;
		background-color: rgba(47, 49, 62, 1);
		border-radius: 5px;

		&:before {
			content: "";
			display: inline-block;
			width: 11px;
			height: 12px;
			background-image: $language;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
		}

		.languageContainer {
			position: relative;
			width: 30px;
			height: 100%;
			display: flex;
			overflow: hidden;
		}

		.language {
			position: absolute;
			left: 0px;
			color: rgba(255, 255, 255, 1);
			font-size: 14px;
			font-family: "Inter-bold", sans-serif;
			opacity: 0;
			transition: linear 0.5s;

			&:first-child {
				transform: translateY(-24px);
			}
			&:last-child {
				transform: translateY(24px);
			}

			&._active {
				transform: translateY(4.5px);
				opacity: 1;
			}
		}
	}
}

.menuContainer {
	position: fixed;
	bottom: 0px;
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
  align-items: flex-start;
  padding-top: 10px;
	background-color: rgba(39, 40, 50, 1);
	z-index: 100;
}

.menu {
	max-width: 550px;
	width: 100%;
	height: 100%;
	display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: start;
}

.item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  text-align: center;
	gap: 4px;
	color: rgba(255, 255, 255, 0.28);
	font-family: "K-extra-bold", sans-serif;
	font-size: 12px;
	text-transform: uppercase;
  transition: linear 0.3s;

	&._active {
		color: rgba(255, 255, 255, 1);
		&:nth-child(1) {
			&:before {
				background-image: $play2;
			}
		}
		&:nth-child(2) {
			&:before {
				background-image: $telegram2;
			}
		}
		&:nth-child(3) {
			&:before {
				background-image: $wtf2;
			}
		}
		&:nth-child(4) {
			&:before {
				background-image: $hist2;
			}
		}
	}

	&:nth-child(1) {
		&:before {
			content: "";
			display: inline-block;
			width: 44px;
			height: 44px;
			background-image: $play1;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
      transition: linear 0.3s;
		}
	}
	&:nth-child(2) {
		&:before {
			content: "";
			display: inline-block;
			width: 44px;
			height: 44px;
			background-image: $telegram1;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
      transition: linear 0.3s;
		}
	}
	&:nth-child(3) {
		&:before {
			content: "";
			display: inline-block;
			width: 44px;
			height: 44px;
			background-image: $wtf1;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
      transition: linear 0.3s;
		}
	}
	&:nth-child(4) {
		&:before {
			content: "";
			display: inline-block;
			width: 44px;
			height: 44px;
			background-image: $hist1;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
      transition: linear 0.3s;
		}
	}
}
