@import "./icons.scss";

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px 23px;
	margin-bottom: 10px;
}

.header {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
  gap: 3px;

	span {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: "Kan-bold", sans-serif;
		font-size: 14px;
		text-transform: uppercase;
		color: rgba(255, 255, 255, 0.54);
		margin-bottom: 5px;
	}

	.icon {
		width: 13px;
		height: 13px;
		background-image: $iconInfo;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.body {
	width: 100%;
	height: 35px;
	background-color: rgb(47, 49, 62);
	border-radius: 12px;
	display: flex;
	align-items: center;
	padding: 0px 10px;
}

.limit {
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2px;

	svg {
		width: 9px;
		height: 16px;
	}

	.counter {
		span {
			font-family: "Kan-bold", sans-serif;
			font-size: 16px;

			&:first-child {
				color: rgba(255, 255, 255, 1);
			}
			&:last-child {
				color: rgba(255, 255, 255, 0.4);
			}
		}
	}
}

.time {
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 2px;

	span {
		display: inline-block;
		font-family: "Roboto-bold", sans-serif;
		font-size: 16px;
		color: rgba(255, 255, 255, 1);
	}
}

.lineContainer {
	position: relative;
	flex-grow: 1;
	height: 30px;
	margin: 0px 5px;

	.bgLine {
		position: absolute;
		top: 50%;
		left: 0px;
		transform: translateY(-50%);
		width: 100%;
		height: 10px;
		border-radius: 10px;
		background-color: rgb(34, 37, 47);
		z-index: 0;
	}

	.bgShadow {
		position: absolute;
		top: 50%;
		left: 0px;
		transform: translateY(-50%);
		width: 100%;
		height: 10px;
		border-radius: 10px;
		background: linear-gradient(
			90deg,
			#ff5251 0%,
			#ff7a51 20%,
			#ffab51 37.5%,
			#ffd951 53%,
			#ffff52 67%,
			#b6ff51 85.5%,
			#51ff51 100%
		);
		filter: blur(3px);
		z-index: 1;
	}

	.bgGradient {
		position: absolute;
		top: 50%;
		left: 0px;
		transform: translateY(-50%);
		width: 100%;
		height: 10px;
		border-radius: 10px;
		background: linear-gradient(
			90deg,
			#ff5251 0%,
			#ff7a51 20%,
			#ffab51 37.5%,
			#ffd951 53%,
			#ffff52 67%,
			#b6ff51 85.5%,
			#51ff51 100%
		);
		z-index: 2;
	}

	.lineCleaner2 {
		position: absolute;
		right: -5px;
		top: 50%;
		transform: translateY(-50%);
		// width: calc(50% + 5px);
		height: 30px;
		background-color: rgb(47, 49, 62);
		z-index: 3;
		backdrop-filter: blur(10px);

		// &::before {
		// 	content: "";
		// 	position: absolute;
		// 	left: -2px;
		// 	top: 1px;
		// 	width: 7px;
		// 	height: 10px;
		// 	background-color: inherit;
		// }
		// &::after {
		// 	content: "";
		// 	position: absolute;
		// 	left: -2px;
		// 	bottom: 1px;
		// 	width: 7px;
		// 	height: 10px;
		// 	background-color: inherit;
		// }
	}

	.lineCleaner {
		position: absolute;
		top: 50%;
		right: 0px;
		transform: translateY(-50%);
		width: 50%;
		height: 10px;
		background-color: rgb(34, 37, 47);
		border-radius: 0px 10px 10px 0px;
		z-index: 4;

		// &::before {
		// 	content: "";
		// 	position: absolute;
		// 	left: -10px;
		// 	top: 0px;
		// 	width: 10px;
		// 	height: 10px;
		// 	border-radius: 10px;
		// 	box-shadow: 3px 0px 0px 0px rgb(34, 37, 47);
		// }
	}
}
