@import "./icons.scss";

.main {
	position: relative;
	width: 100%;
	min-height: 100dvh;
	display: flex;
	flex-direction: column;
	z-index: 1;
	overflow-y: scroll;
	scroll-snap-type: y mandatory;
}

.screen {
	position: relative;
	width: 100%;
	min-height: 100dvh;
	max-height: 100dvh;
	padding: 0px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 100px 20px 0px 20px;
	background-color: rgba(18, 19, 22, 1);
	overflow: hidden;
	transition: linear 0.1s;
	scroll-snap-align: start;

	&._firstScreen {
		&::before {
			content: "";
			position: absolute;
			top: 0%;
			left: 50%;
			transform: translateX(-50%);
			width: 100vw;
			height: 100vh;
			background: linear-gradient(180deg, rgba(55, 55, 66, 1) 0%, rgba(18, 19, 22, 1) 100%);
			z-index: 0;
		}

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: 200vw;
			height: 100vh;
			background-image: $background;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 200%;
			z-index: 0;
		}
	}

	&._bg {
		background-image: $background;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 300%;
		justify-content: center;
	}
}

.signin {
	position: relative;
	width: 100%;
  max-width: 560px;
	height: 373px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.3);
	border: 1px solid rgba(242, 201, 76, 1);
	border-radius: 20px;
	z-index: 2;

	&._signup {
		height: 470px;
		border: none;
	}

	&:before {
		content: "";
		display: block;
		width: 107px;
		height: 107px;
		background-image: $finger;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		margin-bottom: 6px;
	}

	.title {
		font-family: "K-extra-bold", sans-serif;
		font-size: 16px;
		color: rgba(219, 219, 219, 1);
		text-transform: uppercase;
	}

	.inputContainer {
		position: relative;
		width: 235px;
		height: 44px;
		background-color: rgba(255, 255, 255, 0.16);
		border-radius: 50px;
		margin-top: 20px;
	}

	.inputBorder {
		position: absolute;
		bottom: 0px;
		left: 50%;
		transform: translateX(-50%);
		width: 0px;
		height: 44px;
		background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
		border-radius: 50px;
		z-index: 0;
		transition: linear 0.3s;
	}

	.inputLabel {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;

		&::before {
			content: "ID";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			background-color: rgba(27, 27, 31, 1);
			font-family: "Kan-medium", sans-serif;
			font-size: 14px;
			font-family: "Kan-medium", sans-serif;
			color: rgba(125, 125, 125, 1);
			line-height: 100%;
			opacity: 0;
			z-index: 2;
			transition: linear 0.3s;
		}

		&._phone {
			&::before {
				content: "PHONE" !important;
			}
		}
		&._email {
			&::before {
				content: "EMAIL" !important;
			}
		}
	}

	.input {
		position: relative;
		text-align: center;
		width: calc(100% - 4px);
		height: calc(100% - 4px);
		transform: translateX(2px) translateY(2px);
		background-color: rgba(27, 27, 31, 1);
		border-radius: 50px;
		color: rgba(255, 255, 255, 1);
		font-family: "Kan-medium", sans-serif;
		font-size: 14px;
		line-height: 100%;
		z-index: 1;

		&::placeholder {
			color: rgba(125, 125, 125, 1);
			transition: linear 0.4s;
		}
	}

	.inputLabel:has(.input:focus),
	.inputLabel:has(.inputActive) {
		.input::placeholder {
			color: transparent;
		}

		&::before {
			opacity: 1;
			top: 0px;
			transform: translateX(-50%) translateY(calc(-50% + 2px));
			font-size: 13px;
		}

		& + div {
			height: 44px;
			width: 235px;
		}
	}

	.actions {
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;

		.whereId {
			background-color: rgba(48, 48, 56, 1);
			color: rgba(255, 255, 255, 1);
			font-family: "K-regular", sans-serif;
			text-decoration: underline;
			text-transform: uppercase;
			font-size: 12px;
			padding: 4px 10px;
			border-radius: 10px;
		}
	}
}

.btnModal {
	margin-top: 20px;
	font-family: "K-semi-bold", sans-serif;
	font-size: 14px;
	text-transform: uppercase;
	color: rgba(255, 255, 255, 0.38);
	text-decoration: underline;
}

.showMore {
	position: absolute;
	bottom: 0px;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 40px;

	span {
		margin-top: 20px;
		font-family: "K-semi-bold", sans-serif;
		font-size: 20px;
		text-transform: uppercase;
		color: rgba(219, 219, 219, 1);
		text-align: center;
	}

	.arrow {
		width: 42px;
		height: 60px;
		margin-top: 20px;
		background-image: $arrowShowMore;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		animation-name: arrowTranslate;
		animation-duration: 1s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;

		@keyframes arrowTranslate {
			0% {
				transform: translateY(0px);
			}
			50% {
				transform: translateY(10px);
			}
			100% {
				transform: translateY(0px);
			}
		}
	}
}

.titleScreen {
	font-family: "K-semi-bold", sans-serif;
	font-size: 20px;
	text-transform: uppercase;
	text-align: center;
	color: rgba(255, 255, 255, 1);
	margin-bottom: 50px;
	margin-top: 30px;
}

.screenSlider {
	display: flex;
	align-items: center;
}

.sliderContainer {
	width: 100%;
	// max-width: 550px;
	min-height: 450px;

	.slide {
		position: relative;
		height: 450px;
		display: flex;
		justify-content: center;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		margin: 0px 20px;

		.slideContent {
			position: absolute;
			top: -10px;
			width: 235px;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			border-radius: 16px;

			&:before {
				content: "";
				position: absolute;
				left: -1px;
				top: -1px;
				width: calc(100% + 2px);
				height: calc(100% + 2px);
				background: linear-gradient(90deg, #fad4b2 0%, #ffe8a0 100%);
				border-radius: 16px;
				z-index: -1;
			}
		}
	}
}

.info {
	width: 100%;
	max-width: 550px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px 20px;
	gap: 30px;

	.item {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		gap: 10px;

		.num {
			min-width: 66px;
			height: 66px;
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-image: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
			font-family: "K-bold", sans-serif;
			font-size: 32px;
		}

		.text {
			width: 100%;
			background-color: rgba(39, 39, 39, 1);
			padding: 11px 13px;
			border-radius: 10px;
			font-family: "K-medium", sans-serif;
			font-size: 18px;
			color: rgba(255, 255, 255, 1);
		}
	}
}
