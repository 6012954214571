@import "./icons.scss";

.popupContainer {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 23px;
	background-color: rgba(24, 22, 22, 0.5);
	backdrop-filter: blur(7px);
	z-index: 40;
}

.popup {
	position: relative;
	width: 100%;
	// height: 474px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 25px 34px;
	background-color: rgba(27, 28, 36, 1);
	border: 1px solid rgba(242, 201, 76, 1);
	// box-shadow: 0px 0px 9.6px 0px rgba(255, 255, 255, 0.53);
	border-radius: 23px;
}

.close {
	position: absolute;
	right: 16px;
	top: 16px;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;

	&::before {
		content: "";
		display: inline-block;
		width: 12px;
		height: 12px;
		background-image: $closePopup;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
}

.title {
	text-align: center;
	font-family: "K-semi-bold", sans-serif;
	font-size: 18px;
	text-transform: uppercase;
	color: rgba(255, 255, 255, 1);

	span {
		color: rgba(46, 210, 141, 1);
	}

	&._t2 {
		position: relative;
		color: rgba(110, 110, 110, 1);
		padding-bottom: 16px;

		&:before {
			content: "";
			position: absolute;
			bottom: 0px;
			left: 50%;
			width: 80%;
			height: 1px;
			transform: translateX(-50%);
			display: block;
			background-color: rgba(45, 45, 45, 1);
		}
	}
}

.text {
	text-align: center;
	font-family: "K-regular", sans-serif;
	font-size: 14px;
	color: rgba(242, 242, 242, 1);
	margin-top: 16px;

	&._t2 {
		color: rgba(242, 242, 242, 1);

		span {
			font-family: "K-extra-bold", sans-serif;
		}
	}
}

.blockVideo {
	width: 238px;
	height: 114px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #242424;
	margin-top: 16px;
	border-radius: 7px;
	background-image: url("../../public/images/preview.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	.play {
		width: 28px;
		height: 28px;
		background-image: $playVideo;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		cursor: pointer;
	}
}

.button {
	width: 100%;
	height: 55px;
	color: rgba(255, 255, 255, 1);
	font-family: "K-bold", sans-serif;
	font-size: 20px;
	text-transform: uppercase;
	border-radius: 19px;

	&._makeDeposit {
		margin-top: 16px;
		background-color: rgba(255, 168, 28, 1);
	}

	&._help {
    position: relative;
		margin-top: 16px;
		// background-color: rgba(29, 30, 38, 1);
    background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
    z-index: 0;

		&:before {
			content: "";
      position: absolute;
			display: inline-block;
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			left: 2px;
			top: 2px;
      border-radius: 19px;
      background-color: rgba(29, 30, 38, 1);
			// background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
      z-index: -1;
		}
	}
}
